<template>
  <b-card>
    <g-table
      ref="employeeAdvancePayment-table"
      :items="itemsProvider"
      :columns="tableColumns"
      @filtered="onFiltered"
      :noAction="true"
      perPage="25"
      :totalRows="totalRows"
      :createButton="{ visiable: true, permission: 'addAdvancePayment' }"
      :searchInput="{ visiable: true }"
      @on-create="
        (v) => {
          $router.push({ name: 'employeeAdvancePayment-new' });
        }
      "
    >
      <template #actions="{ item }">
        <div class="text-nowrap">
          <!-- edit button  -->
          <b-button
            v-b-tooltip.hover.top="$t('edit')"
            v-permission="'editAdvancePayment'"
            data-action-type="edit"
            variant="flat-secondary"
            class="btn-icon"
            size="sm"
            @click="edit(item)"
          >
            <feather-icon
              icon="EditIcon"
              class="mx-25 clickable"
            />
          </b-button>

          <!-- delete button -->
          <b-button
            v-b-tooltip.hover.top="$t('delete')"
            v-permission="'deleteAdvancePayment'"
            data-action-type="delete"
            variant="flat-danger"
            class="btn-icon"
            size="sm"
            @click="remove(item)"
          >
            <feather-icon
              icon="TrashIcon"
              stroke="red"
              class="danger"
            />
          </b-button>

          <!-- print button -->
          <b-button
            v-b-tooltip.hover.top="$t('print')"
            v-permission="'viewAdvancePayment'"
            variant="flat-primary"
            class="btn-icon"
            size="sm"
            @click="
              (v) => {
                print(item);
              }
            "
          >
            <feather-icon
              icon="PrinterIcon"
              class="mx-25 clickable"
            />
          </b-button>
        </div>
      </template>

    </g-table>
  </b-card>
</template>
<script>
import GTable from '@/pages/Shared/Table.vue';
import reportMixin from '@/mixin/reportMixin';
// import { data } from 'vue-echarts';

export default {
  components: {
    GTable,
  },
  mixins: [reportMixin],
  data() {
    return {
      items: [],
      totalRows: 0,
      currentPage: 1,
      isTableBusy: false,
      perPage: 25,
      filter: {
        OrderClause: ''
      }
    };
  },
  computed: {
    tableColumns() {
      return [
        { key: 'code', label: this.$t('transactionNumber'), sortable: true },
        { key: 'transactionDate', label: this.$t('transactionDate'), sortable: true },
        { key: 'employeeArabicName', label: this.$t('employeeName') },
        { key: 'total', label: this.$t('advancePaymentValue'), sortable: true },
        { key: 'installmentsNumber', label: this.$t('installmentsNumber') },
        { key: 'value', label: this.$t('monthlyPaidValue') },
        { key: 'fromMonth', label: this.$t('fromMonth') },
        { key: 'fromYear', label: this.$t('fromYear') },
        { key: 'toMonth', label: this.$t('toMonth') },
        { key: 'toYear', label: this.$t('toYear') },
        { key: 'actions' },
      ];
    },
  },

  methods: {
    refreshItems() {
      this.$refs['employeeAdvancePayment-table'].refreshTable();
    },

    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    itemsProvider(ctx, callback) {
      const {
        currentPage, perPage, sortBy, sortDesc
      } = ctx;
      this.filter.OrderClause = this.orderQuery(sortBy, sortDesc);
      var params = `?isAdvancePayment=true&pageNumber=${currentPage}&pageSize=${perPage}&`;
      params += this.getFilterObj(this.filter);
      this.isTableBusy = true;
      this.get({ url: `EmployeeBenefitsDeductions${params}` })
        .then(({ data, totalCount }) => {
          this.isTableBusy = false;
          this.totalRows = totalCount;
          data.forEach((item) => {
            item.transactionDate = this.getDate(item.transactionDate);
          });
          callback(data);
        })
        .catch(() => {
          this.isTableBusy = false;
          callback([]);
        });
      return null;
    },

    remove(item) {
      // check if salary calculated
      if (item.isSalaryCalculated) {
        this.doneAlert({ text: this.$t('cantRemoveAsSalaryCalculated'), type: 'error', timer: 8000 });
        return;
      }
      this.confirmAction(
        {
          text: this.$t('areYouSureYouWantToDelete'),
        },
        () => {
          this.delete({
            url: 'EmployeeBenefitsDeductions',
            id: item.id,
          }).then(() => {
            this.doneAlert({ text: this.$t('deletedSuccessfully') });
            this.refreshItems();
          });
        }
      );
    },

    edit(item) {
      this.$router.push({
        name: 'employeeAdvancePayment-edit',
        params: { id: item.id },
      });
    },

    print(item) {
      const reportName = this.isRight ? 'AdvancedPayment-ar' : 'AdvancedPayment-en';
      const printedItem = {
        id: item.id,
      };
      this.printReport(reportName, printedItem);
    },
  },
};
</script>
<style></style>
